export default `{
  _type,
  variant,
  heading,
  items[]{
    source,
    heading,
    link,
  }
}`;
