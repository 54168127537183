import React from 'react';

import { Colors, HexColors } from 'constants/Colors';

interface Props {
  color: Colors;
  className?: string;
}

const VenturesLogo: React.FC<Props> = ({ color, className = '' }) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 92 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80316 20.0771H8.40093L12.5898 0.983238H9.40316L7.78416 9.48938C7.42439 11.4939 7.14171 12.8559 6.85902 15.4H6.75623C6.44785 12.8302 6.16517 11.3654 5.80539 9.41229L4.2121 0.983238H0.64003L4.80316 20.0771ZM13.4266 20H22.4981V16.9933H17.0243V11.4682H20.7763V8.56424H17.0243V4.06704H22.2668V0.983238H13.4266V20ZM23.6788 20H26.5056V6.99665H26.5827C26.7883 7.6648 27.3793 9.36089 27.8933 10.6972L31.6196 20H34.7034V0.983238H31.8508V12.8045H31.7738C31.4654 11.6994 31.0285 10.543 30.5145 9.02681L27.4564 0.983238H23.6788V20ZM39.0582 20H42.6817V4.06704H46.0996V0.983238H35.666V4.06704H39.0582V20ZM52.3374 20.2056C56.2436 20.2056 57.657 18.0469 57.657 13.9609V0.983238H54.2905V13.8324C54.2905 16.1966 53.8022 17.1732 52.4145 17.1732C51.0011 17.1732 50.5129 16.1966 50.5129 13.781V0.983238H46.9151V14.0123C46.9151 17.9955 48.3799 20.2056 52.3374 20.2056ZM67.4224 12.162C69.324 11.057 69.8637 8.89832 69.8637 7.02235C69.8637 2.70503 67.6279 0.983238 64.0816 0.983238H59.4045V20H62.9251V13.2156H64.2101L66.6 20H70.1721L67.4224 12.162ZM62.9251 10.3374V4.06704H64.0302C65.6235 4.06704 66.343 4.7095 66.343 7.09944C66.343 9.59218 65.6235 10.3374 64.0816 10.3374H62.9251ZM71.4636 20H80.5351V16.9933H75.0614V11.4682H78.8133V8.56424H75.0614V4.06704H80.3038V0.983238H71.4636V20ZM86.4498 20.257C89.6107 20.257 91.7179 18.2268 91.7179 15.2715C91.7179 10.0034 84.7794 8.58994 84.7794 5.35195C84.7794 4.40111 85.319 3.81005 86.2442 3.81005C87.4263 3.81005 87.9917 4.83799 88.4285 6.81676L91.6151 6.02011C90.8699 2.80782 89.5593 0.700556 86.3727 0.700556C83.2632 0.700556 81.2587 2.57653 81.2587 5.58324C81.2587 10.8 88.1972 12.2134 88.1972 15.4771C88.1972 16.4022 87.6833 17.2503 86.4755 17.2503C84.985 17.2503 84.3682 15.9911 83.957 13.8838L80.8218 14.4492C81.4643 17.7642 82.6978 20.257 86.4498 20.257Z"
        fill={HexColors[color]}
      />
    </svg>
  );
};

export default VenturesLogo;
