import React from 'react';

import { Colors, HexColors } from 'constants/Colors';

interface Props {
  color: Colors;
  className?: string;
}

const BBGLogo: React.FC<Props> = ({ color, className = '' }) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 83 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.0717 19.4748C77.8853 19.4748 79.1408 18.3866 79.6151 16.9637H79.643L79.6988 19.3074H82.0983V9.87689H76.7971V12.667H78.9734C78.8339 15.3176 78.0806 16.4336 76.6297 16.4336C75.0673 16.4336 74.1466 14.5921 74.1466 9.70949C74.1466 4.82684 74.9278 3.2086 76.4623 3.2086C77.69 3.2086 78.2759 4.21303 78.5549 7.39372L81.8193 7.00311C81.2613 2.09256 79.7546 0 76.4902 0C72.6399 0 70.5474 3.04119 70.5474 9.73739C70.5474 16.3499 72.1377 19.4748 76.0717 19.4748Z"
        fill={HexColors[color]}
      />
      <path
        d="M46.9434 19.251H52.105C55.2857 19.251 57.7131 17.8281 57.7131 14.0614C57.7131 10.9087 56.318 9.84842 54.6161 9.42991V9.37411C56.0669 8.95559 57.3225 7.92326 57.3225 4.96577C57.3225 1.28286 54.8672 0.194733 51.7981 0.194733H46.9434V19.251ZM50.4589 8.23017V3.18012H51.6865C53.1931 3.18012 53.7791 3.71024 53.7791 5.66329C53.7791 7.61635 53.0815 8.23017 51.6865 8.23017H50.4589ZM50.4589 16.4888V10.7691H51.7144C53.3047 10.7691 54.1418 11.5225 54.1418 13.615C54.1418 15.596 53.4722 16.4609 51.8818 16.4609H50.4589V16.4888Z"
        fill={HexColors[color]}
      />
      <path
        d="M58.9404 19.251H64.1021C67.2828 19.251 69.7101 17.8281 69.7101 14.0614C69.7101 10.9087 68.3151 9.84842 66.6132 9.42991V9.37411C68.064 8.95559 69.3195 7.92326 69.3195 4.96577C69.3195 1.28286 66.8643 0.194733 63.7952 0.194733H58.9404V19.251ZM62.4559 8.23017V3.18012H63.6836C65.1902 3.18012 65.7761 3.71024 65.7761 5.66329C65.7761 7.61635 65.0786 8.23017 63.6836 8.23017H62.4559ZM62.4559 16.4888V10.7691H63.7115C65.3018 10.7691 66.1388 11.5225 66.1388 13.615C66.1388 15.596 65.4692 16.4609 63.8789 16.4609H62.4559V16.4888Z"
        fill={HexColors[color]}
      />
      <path d="M43.8741 8.23019H0V10.7971H43.8741V8.23019Z" fill={HexColors[color]} />
    </svg>
  );
};

export default BBGLogo;
