/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render, hydrate } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from 'store';

import App from './App';

if (navigator.userAgent != 'ReactSnap') {
  Sentry.init({
    dsn: 'https://6a8600c1453b4bb383047e78549ffc9e@o523190.ingest.sentry.io/5635247',
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

const rootElement = document.getElementById('root');
if (rootElement !== null) {
  if (rootElement.hasChildNodes()) {
    hydrate(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </Provider>,
      rootElement
    );
  } else {
    render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </Provider>,
      rootElement
    );
  }
}
