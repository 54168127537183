import imageUrlBuilder from '@sanity/image-url';

import { Image } from 'lib/cms/types';

const dataset: string = 'production';
const projectId = 'z4thcwkh';

const imageBuilder = (image: Image, width: number, quality: number, dpr: number): string => {
  if (!projectId) {
    console.error('Sanity project id is missing');

    return '';
  }

  const builder = imageUrlBuilder({
    projectId: projectId,
    dataset: dataset,
  });

  const imageAsImageObject = {
    asset: {
      _ref: image.id,
    },
  };

  return (
    builder.image(imageAsImageObject).width(width).quality(quality).dpr(dpr).auto('format').url() ||
    ''
  );
};

export default imageBuilder;
