import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
}

const DownCaret: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      className={cx('DownCaret', className)}
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50104 10.6098L8.5168 10.6255L17.0021 2.14026L15.2363 0.374512L8.50104 7.1098L1.76575 0.374512L0 2.14026L8.48528 10.6255L8.50104 10.6098Z"
        fill="#242424"
      />
    </svg>
  );
};

export default DownCaret;
